import styles from "./footer.module.sass";

function Footer() {
  return (
    <footer className={styles.mainFooter}>
      <div className={styles.mainFooter__container}>
        <div>
          <h3>Somatech</h3>
          <a href="mailto:contato@somatech.app">Entre em contato</a>
          <a href="https://www.somatech.app">Quem somos</a>
        </div>

        <div className={styles.mainFooter__linkIcon}>
          <a href="https://www.facebook.com/regularize.s1" 
            target="_blank" rel="noreferrer" className={styles.mainFooter__facebook}>
            Facebook
          </a>
          <a href="https://www.instagram.com/regularize.s1" 
            target="_blank" rel="noreferrer" className={styles.mainFooter__instagram}>
            Instagram
          </a>
        </div>
      </div>

      <div className={styles.mainFooter__copy}>
        <p>© Somatech 2023</p>
      </div>
    </footer>
  );
}

export default Footer;
