import { useState } from "react";
import styles from "./faqArea.module.sass";

function FaqArea() {
  const [accordion, setAccordion] = useState(Number);

  return (
    <section className={styles.faqArea}>
      <div className={styles.faqArea__container}>
        <h3>Dúvidas frequentes</h3>

        <div
          className={`${styles.faqArea__faqItem} ${
            accordion === 1 ? styles.faqArea__faqItemActive : null
          }`}
        >
          <div
            className={styles.faqArea__faqItemHead}
            onClick={() => setAccordion(1)}
          >
            Como tenho acesso à solução?
          </div>
          <div className={styles.faqArea__faqItemBody}>
            <p>
              A solução está disponível na plataforma web, que pode ser acessada clicando no link, que consta na notificação de irregularidade veicular, ou por meio do endereço https://web.regularize-s.app e em breve nas plataformas móveis para celulares Android e iPhone.
            </p>
          </div>
        </div>

        <div
          className={`${styles.faqArea__faqItem} ${
            accordion === 2 ? styles.faqArea__faqItemActive : null
          }`}
        >
          <div
            className={styles.faqArea__faqItemHead}
            onClick={() => setAccordion(2)}
          >
            Como é dada a baixa em meus débitos veiculares?
          </div>
          <div className={styles.faqArea__faqItemBody}>
            <p>
              Após a confirmação do pagamento, o sistema realiza a baixa do débito automaticamente. Este processo pode levar até 5 dias úteis para ser concluído.
            </p>
          </div>
        </div>

        <div
          className={`${styles.faqArea__faqItem} ${
            accordion === 3 ? styles.faqArea__faqItemActive : null
          }`}
        >
          <div
            className={styles.faqArea__faqItemHead}
            onClick={() => setAccordion(3)}
          >
            Como funciona a notificação das irregularidades?
          </div>
          <div className={styles.faqArea__faqItemBody}>
            <p>
              Caso o seu veículo possua alguma irregularidade e seja detectado circulando em vias públicas, você será avisado pela Prefeitura por meio de SMS e/ou e-mail. Além disso, se você possuir o app regularize-s instalado em seu celular, você receberá uma notificação em seu aparelho.
            </p>
          </div>
        </div>

        <div
          className={`${styles.faqArea__faqItem} ${
            accordion === 4 ? styles.faqArea__faqItemActive : null
          }`}
        >
          <div
            className={styles.faqArea__faqItemHead}
            onClick={() => setAccordion(4)}
          >
            Quantos cartões de crédito eu posso cadastrar para o pagamento dos meus débitos veiculares?
          </div>
          <div className={styles.faqArea__faqItemBody}>
            <p>
              Não existe limite para a quantidade de cartões cadastrados. Porém, em uma única transação, você pode dividir o valor em até 4 cartões.
            </p>
          </div>
        </div>

        <div
          className={`${styles.faqArea__faqItem} ${
            accordion === 5 ? styles.faqArea__faqItemActive : null
          }`}
        >
          <div
            className={styles.faqArea__faqItemHead}
            onClick={() => setAccordion(5)}
          >
            Posso pagar débitos de veículos que não sou proprietário?
          </div>
          <div className={styles.faqArea__faqItemBody}>
            <p>
              Sim, é possível atrelar um veículo de terceiro à sua conta ou utilizar o seu cartão de crédito na conta de um terceiro.
            </p>
          </div>
        </div>

        <div
          className={`${styles.faqArea__faqItem} ${
            accordion === 6 ? styles.faqArea__faqItemActive : null
          }`}
        >
          <div
            className={styles.faqArea__faqItemHead}
            onClick={() => setAccordion(6)}
          >
            Meus dados estão seguros?
          </div>
          <div className={styles.faqArea__faqItemBody}>
            <p>
              Sim, toda a plataforma está estruturada de acordo com as normas estabelecidas pela LGPD (Lei Geral de Proteção de Dados). Sendo assim, os seus dados serão utilizados apenas internamente, para o pagamento de débitos e regularização de seu veículo.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FaqArea;
