import BoxColumn from "./components/BoxColumn";
import BoxText from "./components/BoxText";
import FaqArea from "./components/FaqArea";
import Footer from "./components/Footer";
import HowWorks from "./components/HowWorks";
import LetsTalk from "./components/LetsTalk";
import Welcome from "./components/Welcome";

function App() {
  return (
    <>
      <Welcome />
      <BoxText>
        <>
          <p className="f-w-medium f-s-lg">Confira as principais vantagens da  regularize-s para você:</p>

          <p className="f-w-medium">Cidadão:</p>
          <ul>
            <li>Regularize o seu veículo de forma rápida e simples, sem burocracia;</li>
            <li>Parcele os seus débitos veiculares em até 21x;</li>
            <li>Receba alertas e fique sabendo se o seu veículo está em situação irregular;</li>
            <li>Tenha a tranquilidade de contar com uma plataforma 100% confiável, conveniada ao seu município.</li>
          </ul>

          <p className="f-w-medium">Gestor público:</p>
          <ul>
            <li>Otimize a arrecadação de débitos e reduza a inadimplência de infrações veiculares;</li>
            <li>Gere benefícios e facilite a vida de seus cidadãos.</li>
          </ul>
        </>
      </BoxText>
      <HowWorks />

      <BoxText>
        <>
          <p className="f-w-medium f-s-lg">Nosso diferencial</p>

          <div>
            <p>O grande diferencial da regularize-s é a sua atuação de forma pró-ativa. A solução detecta os veículos irregulares por meio das câmeras de fiscalização do município e avisa aos seus proprietários, que, muitas vezes, não têm conhecimento sobre a situação irregular.</p>
            <p>Além disso, a solução proporciona um alívio financeiro ao possibilitar o parcelamento dos débitos em até 21x.</p>
          </div>
        </>
      </BoxText>

      <BoxColumn
        title="Aplicativo mobile"
        content="<p>Baixe o nosso app e tenha acesso a todas as funcionalidades da solução: gerencie os seus veículos e meios de pagamento; confira o histórico dos débitos quitados; controle suas transações financeiras e receba notificações da situação de seus veículos.</p><br /><p class=tag>Em breve</p>"
        image="/images/content-mobile.jpg"
        class="mobile"
      />

      <BoxColumn
        title="Aplicativo desktop"
        content="<p>Uma plataforma web que desburocratiza a regularização de seu veículo e facilita o pagamento de seus débitos.</p><br /><p class=linkOut><a href='https://web.regularize-s.app'>Acessar o site</a></p>"
        image="/images/content-device.png"
        class="desktop"
      />

      <FaqArea />
      <LetsTalk />
      <Footer />
    </>
  );
}

export default App;
