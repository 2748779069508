import "./boxText.sass";

type Props = {
  children: JSX.Element,
}

const BoxText: React.FC<Props> = ({ children }) => {
  return (
    <section className="boxText">
      <div className="boxText__container">{children}</div>
    </section>
  );
}

export default BoxText;