import styles from "./welcome.module.sass";

function Welcome() {
  return (
    <section className={styles.welcome}>
      <div className={styles.welcome__content}>
        <div className={styles.welcome__container}>
          <div className={styles.welcome__logo}>
            <h1>
              <a href="/">Somatech</a>
            </h1>
          </div>
          <div className={styles.welcome__text}>
            <h3>
              <span>Você</span> no <br />
              caminho certo
            </h3>
            <p>
              A regularize-s é uma solução voltada para a otimização de
              arrecadação de débitos veiculares por meio de parcerias com as
              principais instituições financeiras do País.
            </p>
          </div>
        </div>
      </div>

      <div className={styles.welcome__image}></div>
    </section>
  );
}

export default Welcome;
