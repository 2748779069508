import React, { useRef } from "react";
import Slider from "react-slick";

import styles from "./howWorks.module.sass";
import "./slick.css";

import image01 from "./howWorksImage01.png";
import image02 from "./howWorksImage02.png";
import image03 from "./howWorksImage03.png";
import image04 from "./howWorksImage04.png";
import image05 from "./howWorksImage05.png";
import image06 from "./howWorksImage06.png";

function HowWorks() {
  const navPagination = useRef<HTMLDivElement | null>(null);

  const sliderSettings = {
    autoplay: true,
    arrows: false,
    autoplaySpeed: 4000,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <section className={styles.howWorks}>
      <div className={styles.howWorks__intro}>
        <div className={styles.howWorks__introContent}>
          <h3>Como funciona?</h3>
          <p>
            O regularize-s é uma plataforma pró-ativa de detecção de
            irregularidades, notificação e parcelamento de débitos.
          </p>
        </div>
      </div>
      <div className={styles.howWorks__slider}>
        <Slider {...sliderSettings}>
          <div className={styles.howWorks__content}>
            <figure>
              <img
                src={image01}
                width="80%"
                alt="Veículo detectado pelas câmeras nas vias públicas"
              />
            </figure>

            <p>Veículo detectado pelas câmeras nas vias públicas.</p>
          </div>

          <div className={styles.howWorks__content}>
            <figure>
              <img
                src={image02}
                width="60%"
                alt="Placa enviada para a central de dados da somatech"
              />
            </figure>

            <p>
              Placa enviada para a central de dados da <strong>soma</strong>
              tech
            </p>
          </div>

          <div className={styles.howWorks__content}>
            <p>Pesquisa interna de irregularidade</p>
            <br />

            <figure>
              <img
                src={image03}
                width="60%"
                alt="Aquisição de dados do veículo e proprietário"
              />
            </figure>

            <p>Aquisição de dados do veículo e proprietário</p>
          </div>
          <div className={styles.howWorks__content}>
            <figure>
              <img
                src={image04}
                width="50%"
                alt="Abordagem do proprietário, encaminhando-o para a plataforma de regularização"
              />
            </figure>

            <p>
              Abordagem do proprietário, encaminhando-o para a plataforma de
              regularização
            </p>
          </div>
          <div className={styles.howWorks__content}>
            <figure>
              <img
                src={image05}
                width="60%"
                alt="Seleção de débitos e realização do pagamento"
              />
            </figure>

            <p>Seleção de débitos e realização do pagamento</p>
          </div>
          <div className={styles.howWorks__content}>
            <figure>
              <img
                src={image06}
                width="60%"
                alt="O valor é repassado para órgão público."
              />
            </figure>

            <p>O valor é repassado para órgão público.</p>
          </div>
        </Slider>

        <div className={styles.howWorks__pagination} ref={navPagination} />
      </div>
    </section>
  );
}

export default HowWorks;
