import styles from "./letstalk.module.sass";
import imageO1 from "./letsTalk_one.jpg";
import imageO2 from "./letsTalk_two.jpg";
import imageO3 from "./letsTalk_three.jpg";

function LetsTalk() {
  return (
    <section className={styles.letstalk}>
      <div className={styles.letstalk__container}>
        <div className={styles.letstalk__content}>
          <h3>Vamos conversar</h3>
          <p>Quer conhecer mais sobre os benefícios da regularize-s? Saiba como ter acesso à plataforma!</p><br />
          <p>E você, gestor público, que tal conhecer as vantagens e os diferenciais da regularize-s para a sua cidade? Entre em contato conosco e vamos, juntos, facilitar a vida dos cidadãos!</p>
          <p className={styles.letstalk__button}>
            <a href="mailto:contato@somatech.app">Entre em contato</a>
          </p>
        </div>

        <div className={styles.letstalk__gallery}>
          <figure>
            <img src={imageO1} alt="Somatech" />
          </figure>
          <div>
            <figure>
              <img src={imageO2} alt="Somatech" />
            </figure>
            <figure>
              <img src={imageO3} alt="Somatech" />
            </figure>
          </div>
        </div>
      </div>
    </section>
  );
}

export default LetsTalk;
