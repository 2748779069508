import styles from "./boxColumn.module.sass";
import classNames from "classnames/bind";
let cx = classNames.bind(styles);

function BoxColumn(props: any) {
  const boxColumn = cx({
    boxColumn: true,
    [`${props.class}`]: props.class,
  });

  return (
    <section className={boxColumn}>
      <div className={styles.boxColumn__column}>
        <div className={styles.boxColumn__columnContent}>
          <h3>{props.title}</h3>
          <div dangerouslySetInnerHTML={{ __html: props.content }} />
        </div>
      </div>

      <div className={styles.boxColumn__columnImage}>
        <img src={props.image} alt={props.title} />
      </div>
    </section>
  );
}

export default BoxColumn;
